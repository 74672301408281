<template>

<b-overlay
  :show = "this.loading"
  spinner-variant="primary"
>
  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('Εγγραφές') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />

          <b-button
              variant="primary"
              type="submit"
              @click="$router.push({ name: 'marketing-pricelist-add'})"
              >
              + {{ $t('add') }}
          </b-button>
        </b-col>
      </b-row>

    </div>

    <b-table
      :key = "keykey"
      ref="refPricelistsTable"
      :items="pricelists"
      responsive
      :fields="columns"  
      :filter="searchQuery"
      primary-key="number"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('NotFound' )+ ' ' + $t('Pricelists')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
      :no-provider-sorting="true"
      :no-provider-paging="true"
      :no-provider-filtering="true"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #head(id)>
          <span>ID</span>
      </template>
      <template #head(name)>
          <span>{{ $t('name') | upper }}</span>
      </template>
      <template #head(label)>
          <span>{{ $t('label') | upper }}</span>
      </template>
      <template #head(active)>
          <span>{{ $t('activeMale') | upper }}</span>
      </template>
      <template #head(stopPropagation)>
          <span>{{ $t('stopPropagation') | upper }}</span>
      </template>
      <template #head(dateFrom)>
          <span>{{ $t('from') | upper }}</span>
      </template>
      <template #head(dateTo)>
          <span>{{ $t('to') | upper }}</span>
      </template>
      <template #head(actions)>
          <span>{{ $t('Actions') }}</span>
      </template>
      

      <!-- Column: Name -->
      <template #cell(name)="data">
        <b-link
          :to="{ name: 'marketing-pricelist', params: { id: data.item.id }}"
          style = "color: #00397A"
        >
          {{ data.value }}
        </b-link>
      </template>
      <!-- Column: active -->
      <template #cell(active)="data">
        <template v-if="data.value === true">
          <b-badge
            pill
            variant="light-success"
          >
              {{$t('yes')}} 
          </b-badge>
        </template>
        <template v-else-if="data.value === false">
          <b-badge
            pill
            variant="light-danger"
          >
              {{$t('no')}} 
          </b-badge>
        </template>
        <template v-else>
          {{data.value}}
        </template>
      </template>
      
      <!-- Column: stopPropagation -->
      <template #cell(stopPropagation)="data">
        <template v-if="data.value === true">
          <b-badge
            pill
            variant="light-success"
          >
             {{$t('yes')}} 
          </b-badge>
        </template>
        <template v-else-if="data.value === false">
          <b-badge
            pill
            variant="light-danger"
          >
             {{$t('no')}} 
          </b-badge>
        </template>
        <template v-else>
          {{data.value}}
        </template>
      </template>    

      <template #cell(actions)="data">
       <b-row class="text-center">
        <b-col class="text-nowrap"  cols="4">
          <feather-icon
            :id="`order-row-${data.item.id}-edit-icon`"
            icon="EditIcon"
            stroke = "#00397A"  
            size="16"
            @click="editPricelist(data.item.id)"
            class="cursor-pointer"
            />
            <b-tooltip
              :title="$t('Edit')"
              placement = "left"
              variant="primary"
              :target="`order-row-${data.item.id}-edit-icon`"
            />
        </b-col>
        <b-col class="text-nowrap" cols="4">
          <feather-icon
            :id="`order-row-${data.item.id}-trash-2-icon`"
            icon="Trash2Icon"
            stroke = "#8b0000"  
            size="16"
            @click="deletePricelistConfirm(data.item.id)"
            class="cursor-pointer"
            />
            <b-tooltip
              :title="$t('Delete')"
              placement = "left"
              variant="primary"
              :target="`order-row-${data.item.id}-trash-2-icon`"
            />
        </b-col>
       </b-row>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{$t("Showing")}} {{ from }} {{$t("to")}} {{ to }} {{$t("of")}} {{ of }} {{$t("entries")}}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            :per-page="perPage"
            v-model="currentPage"
            first-number
            last-number
            :total-rows="of"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>

</b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink, BModal,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BSpinner, BOverlay
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moduleProductPriceRules from '@/store/product-price-rules/moduleProductPriceRules.js'
import router from '@/router/index.js'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BModal,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BSpinner,
    BOverlay,
    vSelect,
    moduleProductPriceRules,
    router,
    ToastificationContent
  },
  data() {
    return {

      keykey:0,
      pricelists:[],
      loading: true,
      user: null,
      columns : [
         { key: 'id', sortable: true },
         { key: 'name',  sortable: true },
         { key: 'label',  sortable: true },
         { key: 'active',   sortable: true },
         { key: 'stopPropagation',  sortable: true },
         { key: 'dateFrom' , sortable: true },
         { key: 'dateTo' , sortable: true },
         { key: 'actions'},
         ],
      
      perPage : 25,
      currentPage: 1,
      perPageOptions : [10, 25, 50, 100],
      searchQuery : '',
      sortBy : 'number',
      isSortDirDesc :true,
    }
  },

  computed:{
    localItemsCount(){
      return this.pricelists ? this.pricelists.length: 0;
    },
    from(){ 
      return  this.perPage * (this.currentPage - 1) + (this.localItemsCount ? 1 : 0);
    },
    to(){  
      return (this.perPage * this.currentPage) < this.localItemsCount ? this.perPage * this.currentPage : this.localItemsCount
    },
    of(){  
      return this.localItemsCount
    },
  },

  methods: {
    fetchPricelists(){
        this.loading= true
        this.$store.dispatch("productPriceRules/getProductPriceRules")
          .then( (productPriceRules) => {
              this.pricelists = productPriceRules.data.result.pricelists.map(function(item){
                let dateFrom = '-'
                let dateTo = '-'
                item.conditions.forEach(condition => {
                  if(condition.type == "timespan"){                   
                    dateFrom = condition.configuration.dateFrom
                    dateTo = condition.configuration.dateTo
                    if(typeof dateFrom == 'number'){
                      let options = {
                        year: 'numeric', month: 'numeric', day: 'numeric',
                        hour: 'numeric', minute: 'numeric', second: 'numeric',
                        hour12: false,
                        timeZone: 'Europe/Athens'
                      }
                      dateFrom = Intl.DateTimeFormat('gr', options).format(new Date(dateFrom))
                    }
                    if(typeof dateTo== 'number'){
                      let options = {
                        year: 'numeric', month: 'numeric', day: 'numeric',
                        hour: 'numeric', minute: 'numeric', second: 'numeric',
                        hour12: false,
                        timeZone: 'Europe/Athens'
                      }
                      dateTo = Intl.DateTimeFormat('gr', options).format(new Date(dateTo))
                    }
                  }})

                  return {
                    id: item.id,
                    name: item.settings.name,
                    label : item.settings.label["el_GR"],
                    active : item.settings.active,
                    stopPropagation : item.settings.stopPropagation,
                    dateFrom: dateFrom,
                    dateTo: dateTo,
                  }
              });
          })
          .catch(() => {
              this.$toast({
                  component: ToastificationContent,
                  props: {
                      title: this.$t("Error fetching pricelists"),
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                  },
              })
          })
          .finally(()=>{
              this.keykey++
              this.loading = false
          })

    },

    editPricelist(id){
      this.$router.push({name: 'marketing-pricelist', params: {id: id}})
    },
    deletePricelistConfirm(id){
      this.$bvModal
        .msgBoxConfirm(this.$t('Please confirm that you want to delete Pricelist ') + id, {
          title: this.$t('Delete Confirmation'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('Confirm'),
          cancelTitle: this.$t('Cancel'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(valueTrue => {
          if (valueTrue) {this.deletePricelist(id)}
        })
    },
    deletePricelist(id){ 
      this.$store.dispatch("productPriceRules/deletePricelist", id)
      .then( () => {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: this.$t("Pricelist Successfully deleted"),
              icon: 'CheckIcon',
              variant: 'success',
          },
       })
      })
      .catch( () => {
        this.$toast({
            component: ToastificationContent,
            props: {
                title: this.$t("Error deleting Pricelist"),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
            },
        })
      })
      .finally(()=>{
        this.fetchPricelists()  
      })
    },
  },

  created() {
      this.loading = true;

      if (!store.hasModule('productPriceRules')) store.registerModule('productPriceRules', moduleProductPriceRules)
      this.pricelists = this.$store.state.productPriceRules.pricelists

      if(this.pricelists==null){
        this.fetchPricelists()
      }
      else {
          this.loading = false 
      }          
    },
}
</script>


<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
